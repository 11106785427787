<template>
    <div class="pa-8">
        <div class="radius-16 shadow-container pl-8 py-6">
            <div class="font-bold font-22">New Publish Request</div>
            <span class="font-16">Past 7 days</span>
            <div class="mt-6 horizontal-scroll flex-container full-width">
                <div v-for="item in 5" :key="item" class="radius-8 border border--grey pl-6 pt-6 pb-4 mr-4" style="width: 400px">
                    <div class="font-12 mb-4 color-grey">Yesterday, 12 Mar 2020</div>
                    <div class="font-18 mb-4 font-bold">Djemari Salon and Spa</div>
                    <div class="flex-container">
                        <div class="font-14 mr-4"><v-icon small color="#CDD2DA" >mdi-map-marker</v-icon>12 Location</div>
                        <div class="font-14"><v-icon small color="#CDD2DA" >mdi-account-multiple</v-icon>12 Staff</div>
                    </div>
                    <div class="text-right pr-4" style="width: 360px">
                        <v-btn depressed outlined color="#E0E0E0" ><span style="color: #000000" @click="showDialog = true">Open</span></v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8">
            <v-btn depressed class="mr-2">Published Merchant</v-btn>
            <v-btn depressed class="mr-2">Publish Request</v-btn>
            <v-btn depressed>Log</v-btn>
        </div>
        <div class="radius-16 shadow-container pa-6 mt-6">
            <v-data-table
                :headers="headers"
                :items="data"
                :items-per-page="15"
                hide-default-footer
                class="elevation-1 mytable mb-4"
                style="border-bottom: none"
                @click:row="rowClick">
                <template v-slot:header.name="{ header }">
                    <span style="color: #00B082">{{ header.value }}</span>
                </template>
            </v-data-table>
        </div>
        <v-dialog v-model="showDialog">
            <v-card>
                <v-toolbar color="elevation-0" class="d-flex">
                  <v-toolbar-title >
                      <span class="ml-4 font-weight-bold font-22" style="color: #000000">Merchant Review</span>
                  </v-toolbar-title>
                  <v-toolbar-items style="position: fixed; right: 10px;">
                      <v-btn icon @click="handleClose">
                          <v-icon color="#000000">mdi-close</v-icon>
                      </v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <div class="d-flex mt-4">
                  <div class="px-9 full-width" style="max-width: 416x">
                      <div class="mb-3">
                          <v-btn outlined raised block elevation="5" :color="showMerchant ? '#00D79E' : '#FFFFFF'" class="radius-8" @click="showMerchant = true, showService = false, showLocation = false">
                            <span class="text-capitalize" :style="showMerchant ? '' : 'color: #000000'">Merchant Info</span>
                          </v-btn>
                      </div>
                      <div class="mb-3">
                          <v-btn outlined raised block elevation="5" :color="showService ? '#00D79E' : '#FFFFFF'" class="radius-8" @click="showMerchant = false, showService = true, showLocation = false">
                            <span class="text-capitalize" :style="showService ? '' : 'color: #000000'">Service & Class</span>
                          </v-btn>
                      </div>
                      <div>
                          <v-btn outlined raised block elevation="5" :color="showLocation ? '#00D79E' : '#FFFFFF'" class="radius-8" @click="showMerchant = false, showService = false, showLocation = true">
                            <span class="text-capitalize" :style="showLocation ? '' : 'color: #000000'">Location</span>
                          </v-btn>
                      </div>
                  </div>
                  <div v-show="showMerchant" class="px-9 full-width" style="border-left: 1px solid #EEEEEE; height: 400px;">
                      <span class="font-14">Name</span>
                      <div class="font-20 font-bold mb-8">Djemari Salon & Pizza</div>

                      <span class="font-14">Phone Number</span>
                      <div class="font-20 font-bold mb-8">(ID) +62 892-8192-8222</div>

                      <span class="font-14">Email</span>
                      <div class="font-20 font-bold mb-8">djemarisalon@gmail.com</div>

                      <span class="font-14">Description</span>
                      <div class="font-16 mb-8">Djemari adalah salah satu tempat terbaik untuk merelaksasikan badan dan pikiran Anda dengan treatment spesial dari Kami. Djemari menawarkan 25 jenis varian service yang akan memanjakan Anda.</div>
                  </div>
                  <div v-show="showService" class="px-9 full-width mb-4" style="border-left: 1px solid #EEEEEE; height: 400px;">
                      <v-btn text>Service</v-btn>
                      <v-btn text>Class</v-btn>
                      <div class="vertical-scroll mt-2" style="height: 100%; max-height: 350px;">
                          <div v-for="i in 6" :key="i" class="border border--grey radius-8 pa-5 mb-2" style="height: 99px">
                            <span class="font-12">Haircut + Full Beard + Beard Dye + Blue Wax/Black Mask</span>
                            <viewer
                              ref="viewer"
                              :images="images"
                              rebuild
                              class="viewer"
                            >
                              <template slot-scope="scope">
                                <img v-for="src in scope.images" :src="src" :key="src" class="mr-2" style="width: 32px; height: 32px; object-fit: cover">
                              </template>
                            </viewer>
                        </div>
                      </div>
                  </div>
                  <div v-show="showLocation" class="px-9 full-width mb-4 vertical-scroll" style="border-left: 1px solid #EEEEEE; height: 400px;">
                      <div v-for="(item, idx) in 5" :key="item" class="px-6 py-2 mb-3 border border--grey" style="border-radius: 8px;">
                        <div class="d-flex pt-2 pointer" @click="handleShow(idx)">
                            <div class="full-width font-bold font-14">Djemari Salon - Kaliurang</div>
                            <div class="mt-n2">
                                <v-btn icon @click="handleShow(idx)">
                                    <v-icon >{{ tempCurr[idx] ? 'mdi-chevron-down' : 'mdi-chevron-right'}}</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div v-show="tempCurr[idx]" class="mt-2">
                          <div class="font-12">(ID) +6282-9120-1212</div>
                          <div class="mt-5">
                              <v-avatar v-for="i in 5" :key="i" size="32" tile class="mr-2">
                                  <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
                              </v-avatar>
                          </div>
                          <hr class="mt-4" style="border: 1px dashed #E0E0E0">
                          <div class="d-flex mt-6">
                            <div class="full-width">
                              <div style="color: #747474">Facility</div>
                              <ul class="list-facility mt-2">
                                <li v-for="i in 6" :key="i" class="font-10">Air Conditioner</li>
                              </ul>
                            </div>
                            <div class="full-width">
                              <div style="color: #747474">Opening Hours</div>
                              <ul class="list-facility mt-2">
                                <li v-for="i in 6" :key="i" class="font-10">Mon 09:00 - 15:00</li>
                              </ul>
                            </div>
                            <div class="full-width">
                              <div style="color: #747474">Location</div>
                              <div class="mt-2 font-10">
                                Jl. Mataram Timur Raya No.4 Sumberadi Mlati Sleman D.I. Yogyakarta.
                              </div>
                              <div>
                                <gmap-map
                                  ref="storeLocationOnMap"
                                  :center="marker.position"
                                  :zoom.sync="zoom"
                                  :options="mapOptions"
                                  style="width: 100%; height: 300px; margin-bottom: 20px;"
                                  @click="onMapClick">
                                  <gmap-marker
                                    :position="marker.position"
                                    :clickable="true"
                                    :draggable="true"
                                    @dragend="onMapClick"
                                  />
                                </gmap-map>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; height: 80px">
                  <div class="text-right full-width">
                      <v-btn class="text-capitalize mr-2" color="#E0E0E0" outlined depressed @click="showDialogFeed = true"><span style="color: #000000">Give feedback</span></v-btn>
                      <v-btn class="text-capitalize" color="#00D79E" depressed style="color: #FFFFFF" @click="showConfirm = true">Publish</v-btn>
                  </div>
              </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogFeed" width="50%">
          <v-card>
            <v-toolbar color="elevation-0" class="d-flex">
                  <v-toolbar-title >
                      <span class="ml-4 font-weight-bold font-22" style="color: #000000">Give feedback</span>
                  </v-toolbar-title>
                  <v-toolbar-items style="position: fixed; right: 10px;">
                      <v-btn icon @click="handleClose">
                          <v-icon color="#000000">mdi-close</v-icon>
                      </v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <div class="pa-10">
                <v-textarea v-model="form.bio" outlined color="teal" placeholder="Write down your feedback"></v-textarea>
              </div>
              <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; height: 80px">
                  <div class="text-right full-width">
                      <v-btn class="text-capitalize" color="#00D79E" depressed style="color: #FFFFFF" >Publish</v-btn>
                  </div>
              </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showConfirm" max-width="50%">
          <v-card>
              <v-toolbar color="elevation-0">
                  <v-toolbar-title >
                  <span class="ml-4 font-weight-bold">Publish Confirmation</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                  <v-btn icon @click="handleClose">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <div class="pa-8">
                  You are about to publish Djemari Salon and Spa to marketplace. Are you sure?
              </div>
              <div class="d-flex pt-4 px-4" style="height: 80px">
                  <div class="text-right full-width">
                      <v-btn class="text-capitalize mr-2" outlined depressed @click="showConfirm = false, showDialog = true" style="border-color: #E0E0E0">Back to Setting</v-btn>
                      <v-btn class="text-capitalize" color="#00D79E" depressed style="color: #FFFFFF" @click="updatePackage">Yes</v-btn>
                  </div>
              </div>
          </v-card>
      </v-dialog>
    </div>
</template>
<script>
import { gmapApi } from 'vue2-google-maps'
import axios from 'axios'
export default {
  name: 'Marketplace',
  data () {
    return {
      headers: [
        {
          text: 'Merchant',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Request Date',
          align: 'right',
          value: 'fjoined_date',
          width: 150
        },
        {
          text: 'Publish Date',
          align: 'center',
          value: 'fpublish_date'
        },
        {
          text: 'Location',
          align: 'left',
          value: 'total_location'
        },
        {
          text: 'Staff',
          align: 'left',
          value: 'total_staff'
        },
        {
          text: 'Service',
          align: 'left',
          value: 'total_service'
        },
        {
          text: 'Class',
          align: 'left',
          value: 'total_class'
        },
        {
          text: 'Product',
          align: 'left',
          value: 'total_product'
        }
      ],
      data: [
        {
          name: 'Djemari Salon',
          fjoined_date: '12 Mar 2020',
          fpublish_date: '12 Mar 2020',
          total_location: 13,
          total_staff: 12,
          total_service: 10,
          total_class: 2,
          total_product: 10
        }
      ],
      showDialog: false,
      showDialogFeed: false,
      showConfirm: false,
      showMerchant: true,
      showService: false,
      showLocation: false,
      tempCurr: [false, false, false, false, false],
      mapOptions: {
        minZoom: 5,
        maxZoom: 20,
        draggable: true,
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        scaleControl: true,
        streetViewControl: false
      },
      zoom: 10,
      marker: {
        position: {
          lat: -7.7470633,
          lng: 110.42026194444445
        },
        label: {
          color: 'black',
          fontFamily: 'Material Icons',
          fontSize: '20px',
          text: 'face'
        }
      },
      images: ['https://cdn.vuetifyjs.com/images/profiles/marcus.jpg', 'https://www.w3schools.com/css/rock600x400.jpg'],
      form: {
        bio: ''
      }
    }
  },

  computed: {
    google: gmapApi
  },

  // mounted () {
  //   if (this.$route.path === '/zenwel-biz/marketplace') {
  //     this.$router.push('/zenwel-biz/business?tabs=3')
  //   }
  // },

  methods: {
    handleShow (idx) {
      this.$set(this.tempCurr, idx, !this.tempCurr[idx])
    },

    onMapClick (val) {
      this.marker.position.lat = val.latLng.lat()
      this.marker.position.lng = val.latLng.lng()
      this.getFormattedAddress(val.latLng.lat(), val.latLng.lng())
    },

    getGeolocation () {
      // const infowindow = new this.google.maps.InfoWindow()
      const map = this.$refs["'storeLocationOnMap'"].$mapObject
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        map.setCenter(pos)
        this.marker.position = pos
        this.getFormattedAddress(pos.lat, pos.lng)
      })
    },

    getFormattedAddress (lat, lng) {
      this.formEdit.location_lat = lat
      this.formEdit.location_lon = lng
      axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: lat + ',' + lng,
          key: 'AIzaSyA0adbyptSOlZbbq9T_z6X4y7TS9rOFryo'
        }
      }).then(response => {
        this.formEdit.address = response.data.results[0].formatted_address
      }).catch(e => {
        // this.errors.push(e)
      })
    },

    handleClose () {
      console.log('')
    },

    rowClick () {
      console.log('')
    }
  }
}
</script>
